<template>
  <div>
    <bfc-bar></bfc-bar>
    <lab-menu></lab-menu>
    <v-divider></v-divider>
    <div style="height: 20px"></div>
    <v-app-bar color="gray" style="height: 48px">
      <v-breadcrumbs :items="breadcrumbs" style="margin-top: -16px">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div style="height: 20px"></div>
    <v-card>
      <v-card-text>
        <v-file-input
          v-model="pdf"
          ref="pdf"
          id="pdf"
          chips
          multiple
          label="Upload PDF proposal"
          @change="handlerUpload"
        ></v-file-input>
        <div class="text-left">
          <a v-if="pdfFile" @click="downloadFile(caseNo, pdfFile)">
            Click to download {{ pdfFile }}</a
          >
        </div>

        <v-file-input
          v-model="vdo"
          ref="vdo"
          id="vdo"
          small-chips
          multiple
          label="Upload video zip file"
          @change="handlerUpload"
        ></v-file-input>
        <div class="text-left">
          <a v-if="vdoFile" @click="downloadFile(caseNo, vdoFile)">
            Click to download {{ vdoFile }}</a
          >
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="saveUpload">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="96"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import SystemBar from "../../library/system-bar/system-bar";
//import { mapActions, mapGetters } from "vuex";
//import { nullOrUndefined } from "../../../../plugins/libs.js";
import LabMenu from "../menubar/menubar";
import axios from "axios";
export default {
  components: {
    "bfc-bar": SystemBar,
    "lab-menu": LabMenu,
  },
  data() {
    return {
      overlay: false,
      vdo: "",
      pdf: "",
      caseNo: "",
      pdfData: {},
      pdfFile: "",
      vdoFile: "",
      files: {
        pdf: "",
        vdo: "",
      },
      caseStatus: {},

      url: process.env.VUE_APP_LOYALTY_API_URI,
      breadcrumbs: [
        {
          text: "UPLOAD PDF FILES",
          disabled: false,
          to: { path: "/lab/dashboard" },
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.caseNo = this.$route.params.caseNo;
      console.log("init");
      let payload = {
        caseNo: this.$route.params.caseNo,
        custId: this.custId,
        selectField: "caseNo _id custId doctorId pdfData caseStatus",
      };

      await axios
        .post(
          this.url + "/api/bfc/v1/case/caseInfo",
          payload, // need to change
          {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (response) => {
          console.log("caseInfo");
          console.log(response.data.data);
          let result = response.data.data[0];

          // this.eForm.firstname = response.data.data[0].caseDetail.firstname;
          // this.eForm.lastname = response.data.data[0].caseDetail.lastname;
          // this.eForm = response.data.data[0].caseDetail;
          // this.caseId = response.data.data[0]._id;
          // this.runNo = response.data.data[0].runNo;
          if (typeof result.pdfData !== "undefined") {
            console.log(typeof result.pdfData);
            for (const itemObj in result.pdfData) {
              console.log(itemObj);
              if (itemObj != "updatedAt") {
                result.pdfData[itemObj].forEach((item) => {
                  console.log("foreach");
                  console.log(item);
                  if (item.mimetype == "pdf") {
                    this.pdfFile = item.name;
                  } else if (item.mimetype == "vdo") {
                    this.vdoFile = item.name;
                  }
                });
              }
            }
          }
          this.caseStatus = result.caseStatus;
        });
    },

    downloadFile(caseNo, filename) {
      axios({
        url: this.url + "/public/cases/" + caseNo + "/" + filename,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },

    async saveUpload() {
      //
      this.overlay = true;
      let formData = new FormData();
      //console.log(this.pdf[0].File);
      formData.append("pdf", this.pdf[0]);
      formData.append("vdo", this.vdo[0]);

      let caseData = JSON.stringify({
        caseNo: this.$route.params.caseNo,
        caseStatus: this.caseStatus,
        path: "",
      });
      formData.append("data", caseData);

      let result = await axios
        .post(this.url + "/api/bfc/v1/lab/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 300 * 60 * 60,
          keepAliveTimeout: 1000 * 60 * 60,
        })
        .then(function (data) {
          // console.log(data);
          //  console.log(data.data.statusCode);
          if (data.data.statusCode == 0) {
            alert("Uploaded");

            //return data.data;
          }
          //console.log(data.data);
        })
        .catch((e) => {
          //this.overlay = false;
          //alert("Upload failed");
          //this.overlay = false;
          console.log("FAILURE!! " + e);
        });
      this.overlay = false;
      if (result) {
        if (result.statusCode == 0) {
          //this.overlay = false;
          this.$router.back();
        } else {
          alert("UPLOAD FAILED");
        }
      }
    },
    handlerUpload(e) {
      //let uploadedFiles = this.$refs.pdf.files;
      console.log(e);
      //console.log(e.dataTransfer);
      //console.log(this.pdf);
      // console.log(uploadedFiles);
      // this.files[_type] = uploadedFiles;
      // console.log(this.files);
    },
  },
};
</script>
